import React, { useState } from 'react';

export default function Contact({id, heading, tagline}) {
    const emptyForm = {
        name: "",
        message: "",
        email: "",
    };
    const [values, setValues] = useState(emptyForm);
    
    function handleInputChange(event) {
        const {name, value} = event.target;
        setValues({
            ...values,
            [name]: value
        });
    };

    function handleSubmit(event) {
        event.preventDefault();
        const contactRequest = fetch(`/api/ContactForm`, {
            method: 'POST',
            mode: 'cors',
            body: JSON.stringify(values)
        });
        contactRequest
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`HTTP error: ${response}`);
                }
                return response.json();
            })
            .catch((error) => {
                console.error(error);
            })
        setValues(emptyForm);
    };

    return (
        <section id={id}>
        <h2>{heading}</h2>
        <p>{tagline}</p>
        <form id="contact-form">
            <label htmlFor="contact-form-name">Name: </label>
            <input id="contact-form-name" name="name" type="text" minLength="2" maxLength="64" required onChange={handleInputChange} value={values.name} /><br />
            <label htmlFor="contact-form-message">Message: </label>
            <textarea id="contact-form-message" name="message" required onChange={handleInputChange} value={values.message} rows="5" /><br />
            <label htmlFor="contact-form-email">Email: </label>
            <input id="contact-form-email" name="email" type="email" required onChange={handleInputChange} value={values.email} /><br />
            <button type="button" onClick={handleSubmit}>Submit</button>
        </form>
        <br />
        </section>
    )
};