import * as EBC from './components';
import React, { useState } from 'react';
import { createRoot } from 'react-dom/client';
import data from './content/ebcsite.json';
import './index.css';
import '@fontsource/poppins';

//const header = data.ebcyber.header;
const sections = Object.values(data.ebcyber.main.sections);
const services = sections.filter(section => {return section.id === "Services"})[0];
const about = sections.filter(section => {return section.id === "About"})[0];
const contact = sections.filter(section => {return section.id === "Contact"})[0];
const footer = data.ebcyber.footer;

export default function EBCSite() {
  // const [activeSection, setActiveSection] = useState('About');
  const [sectionContent, setSectionContent] = useState(
    {...about}
  )

  function handleOnShow(event){
    const section = event.target.value;
    // setActiveSection(section)
    switch(section){
      case "About":
        setSectionContent({...about});
        break;
      case "Services":
        setSectionContent({...services});
        break;
      case "Contact":
        setSectionContent({...contact});
        break;
      default:
        setSectionContent({...about});
        break;
    };
  }
  return (
    <>
      <EBC.Header />
      <nav>
        <button className="nav-button" id="nav-button-about" type="button" value="About" onClick={handleOnShow}>About</button>
        <button className="nav-button" id="nav-button-services" type="button" value="Services" onClick={handleOnShow}>Services</button>
        <button className="nav-button" id="nav-button-contact" type="button" value="Contact" onClick={handleOnShow}>Contact</button>
      </nav>
      <main>
        <EBC.Sections {...sectionContent} />
      </main>
      <EBC.Footer {...footer} />
    </>
  )
};

const root = createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <EBCSite />
  </React.StrictMode>
);