// import React from 'react';
import {ReactComponent as ReactLogo} from '../img/ebc-logo-long.svg';

// export default function Header() {
//     return (
//         <header className="site-header">
//             <h1>EB Cyber</h1>
//         </header>
//   );
// }
export default function Header() {
    return (
        <header className="site-header">
            <ReactLogo />
        </header>
  );
}