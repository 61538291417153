import {Contact} from '../components';

export default function Sections({...props}){

    if (props.id === "Contact") {
        return (
            <Contact {...props} />
        )
    } else {
        return (
            <section id={props.id} className="w3-container">
                <h2>{props.heading}</h2>
                <p>{props.tagline}</p>
                {props.content.map((article, index) => {
                    return (
                        <article key={index} id={article.id} className="w3-container">
                            <header>
                                <h3>{article.heading}</h3>
                            </header>
                            <p>{article.content}</p>
                        </article>
                    )
                })}
            </section>
        )
    }
}