export default function Footer({registration, address, copyright}) {
  return (
    <footer>
      <small>
        <address>
          {registration}<br />
          Registered office address: {address}
        </address>
        <p>{copyright}</p>
      </small>
    </footer>
  );
}